import { Navigate, Route, Routes } from 'react-router-dom';
import HomePage from './pages/Home';
import GalleryPage from './pages/Gallery';

function App() {
  return (
    <div className="root">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
}

export default App;
