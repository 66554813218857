import './index.scss';

import Logo from '../../assets/logo.png';
import Twitter from '../../assets/twitter.png';
import Discord from '../../assets/discord.png';
import { useMatch, useNavigate } from 'react-router-dom';

interface AppbarMenuItem {
  path: string;
  name: string;
}

function NavItem({ path, name }: AppbarMenuItem) {
  const match = useMatch(path);
  const navigate = useNavigate();
  return (
    <div
      className={`appbar-route-item ${match ? 'active' : ''}`}
      onClick={() => {
        navigate(path);
      }}
    >
      {name}
    </div>
  );
}

const Header = (): JSX.Element => {
  return (
    <div className="appbar">
      <div className="appbar-logo">
        <img src={Logo} alt="logo" />
      </div>
      <div style={{ flex: 1 }} />
      <div className="appbar-route">
        <NavItem path="/" name="Home" />
        <NavItem path="/gallery" name="Gallery" />
      </div>
      <div className="appbar-link">
        <a className="appbar-link-item appbar-twitter" href="https://twitter.com" target="_blank" rel="noreferrer">
          <img src={Twitter} alt="twitter" />
        </a>
        <a className="appbar-link-item appbar-discord" href="https://discord.com" target="_blank" rel="noreferrer">
          <img src={Discord} alt="discord" />
        </a>
      </div>
    </div>
  );
};

export default Header;
