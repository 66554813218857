import './index.scss';

import Logo from '../../assets/logo-footer.png';
import { useNavigate } from 'react-router-dom';

interface AppbarMenuItem {
  path: string;
  name: string;
}

function NavItem({ path, name }: AppbarMenuItem) {
  const navigate = useNavigate();
  return (
    <div
      className={`footer-links-item`}
      onClick={() => {
        navigate(path);
      }}
    >
      {name}
    </div>
  );
}

const Footer = (): JSX.Element => {
  return (
    <div className="footer">
      <div className="footer-logo">
        <img src={Logo} alt="logo" />
      </div>
      <div className="footer-links">
        <NavItem path="/" name="HOME" />
        <NavItem path="/gallery" name="GALLERY" />
        <a className="footer-links-item" href="https://discord.com" target="_blank" rel="noreferrer">
          DISCORD
        </a>
        <a className="footer-links-item" href="https://twitter.com" target="_blank" rel="noreferrer">
          X
        </a>
      </div>
    </div>
  );
};

export default Footer;
