import './index.scss';

import BannerBackground from '../../assets/banner_background.png';
import BannerPeople from '../../assets/banner_people.png';
import BannerImage from '../../assets/banner-image.png';
import ConnectWalletButton from '../../assets/connect_wallet_button.png';
import BannerScroll1 from '../../assets/banner_scroll_1.png';
import BannerScroll2 from '../../assets/banner_scroll_2.png';
import StoryIcon from '../../assets/story.png';
import WelcomeIcon from '../../assets/welcome_icon.png';
import FaqIcon from '../../assets/faq_icon.png';
import Arrow from '../../assets/arrow.png';
import Footer from '../../components/Footer';

const HomePage = (): JSX.Element => {
  return (
    <>
      <div className="home">
        <div className="home-banner">
          <img className="home-banner-background" src={BannerBackground} alt="background" />
          <img className="home-banner-people" src={BannerPeople} alt="people" />
          <img className="home-banner-image" src={BannerImage} alt="avatars" />
          <img className="home-banner-connect" src={ConnectWalletButton} alt="connect" />
          <img className="home-banner-scroll-1" src={BannerScroll1} alt="scroll" />
          <img className="home-banner-scroll-2" src={BannerScroll2} alt="scroll" />
        </div>
        <div className="home-story">
          <img className="home-story-icon" src={StoryIcon} alt="story" />
          <div className="home-story-contents">
            <div className="home-story-contents-title">BTC NFT STORY</div>
            <div className="home-story-contents-describe">
              Bzuki is an NFT project based on bitcoin ecosystem.We are fortunate to benefit from the fair environment
              of bitcoin ecosystem,allowing everyone who is passionate about NFT to truly fairly obtain Bzuki.There is
              no rarity level for Bzuki NFT, and its values is reflected in each person's subjective aesthetic!Bzuki NFT
              will become the entry barrier for Bzuk Labs in the future.More amazing things on the way,so stay tuned!
            </div>
            <div className="home-story-contents-button">Buy A Bzuki</div>
          </div>
        </div>
        <div className="home-welcome">
          <div className="home-welcome-wrap">
            <img className="home-welcome-icon" src={WelcomeIcon} alt="welcome" />
            <div className="home-welcome-title">Welcome To BTC NFT</div>
            <div className="home-welcome-describe">
              How many BTC NFTs are there
              <br />
              How much does it cost to mint a BTC NFT?
            </div>
            <div className="home-welcome-button">View Your BTC NFT</div>
          </div>
        </div>
        <div className="home-faq">
          <img className="home-faq-icon" src={FaqIcon} alt="faq" />
          <div className="home-faq-questions">
            <div className="home-faq-questions-title">THE FAQ</div>
            <div className="home-faq-questions-item">
              <div className="home-faq-questions-item-title">What is inscription?</div>
              <div className="home-faq-questions-item-icon">
                <img src={Arrow} alt="arrow" />
              </div>
            </div>
            <div className="home-faq-questions-item">
              <div className="home-faq-questions-item-title">What is BTC NFT?</div>
              <div className="home-faq-questions-item-icon">
                <img src={Arrow} alt="arrow" />
              </div>
            </div>
            <div className="home-faq-questions-item">
              <div className="home-faq-questions-item-title">How much does it cost to mint a BTC NFT?</div>
              <div className="home-faq-questions-item-icon">
                <img src={Arrow} alt="arrow" />
              </div>
            </div>
            <div className="home-faq-questions-item">
              <div className="home-faq-questions-item-title">What rights do I have to BTC NFT?</div>
              <div className="home-faq-questions-item-icon">
                <img src={Arrow} alt="arrow" />
              </div>
            </div>
            <div className="home-faq-questions-buy">Buy A Bzuki</div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HomePage;
