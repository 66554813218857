import './index.scss';
import datas from '../../assets/json/nfts.json';
import { useEffect, useRef, useState } from 'react';
import { NFT } from '../../models/nft';
import InfiniteScroll from 'react-infinite-scroller';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Arrow from '../../assets/arrow.png';
import Checkbox from '@mui/material/Checkbox';

const backgroundList: string[] = ['blue', 'green', 'hue', 'laser', 'mystery', 'psychedelic', 'purple'];
const clothingList: string[] = ['dark-vest', 'light-vest', 'jacket', 'sci-fi', 'technical', 'kimono'];
const earringsList: string[] = ['clock', 'ear-clip', 'tag'];
const eyeList: string[] = ['gold', 'green', 'red', 'purple'];
const figureList: string[] = ['blue', 'bright-blue', 'grey', 'ordinary', 'purple', 'red', 'yellow'];
const hairList: string[] = ['gold', 'green', 'red', 'blue'];
const hornLeftList: string[] = ['demon', 'devil', 'dragon'];
const hornRightList: string[] = ['demon', 'devil', 'dragon'];
const mouthList: string[] = ['laughing', 'ordinary', 'smile'];
const tattooList: string[] = ['arm', 'forehead', 'sun'];
const weaponList: string[] = ['long sword', 'scimitar', 'dagger'];

const GalleryPage = (): JSX.Element => {
  const [totalDatas, setTotalDatas] = useState<NFT[]>([]);
  const [currentDatas, setCurrentDatas] = useState<NFT[]>([]);
  const [currentPageData, setCurrentPageData] = useState<NFT[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const background = useRef(-1);
  const clothing = useRef(-1);
  const earrings = useRef(-1);
  const eye = useRef(-1);
  const figure = useRef(-1);
  const hair = useRef(-1);
  const hornLeft = useRef(-1);
  const hornRight = useRef(-1);
  const mouth = useRef(-1);
  const tattoo = useRef(-1);
  const weapon = useRef(-1);

  const onBackgroundChange = async (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      background.current = index;
    } else {
      background.current = -1;
    }
    initList();
  };

  const initList = async () => {
    let _totalDatas: NFT[] = [...totalDatas];

    if (background.current !== -1) {
      _totalDatas = _totalDatas.filter((value) => {
        let isWant: boolean = false;
        value.traits.forEach((element) => {
          if (element.trait_type === 'background' && element.value === backgroundList[background.current]) {
            isWant = true;
          }
        });
        return isWant;
      });
    }
    if (clothing.current !== -1) {
      _totalDatas = _totalDatas.filter((value) => {
        let isWant: boolean = false;
        value.traits.forEach((element) => {
          if (element.trait_type === 'clothing' && element.value === clothingList[clothing.current]) {
            isWant = true;
          }
        });
        return isWant;
      });
    }
    if (earrings.current !== -1) {
      _totalDatas = _totalDatas.filter((value) => {
        let isWant: boolean = false;
        value.traits.forEach((element) => {
          if (element.trait_type === 'earrings' && element.value === earringsList[earrings.current]) {
            isWant = true;
          }
        });
        return isWant;
      });
    }
    if (eye.current !== -1) {
      _totalDatas = _totalDatas.filter((value) => {
        let isWant: boolean = false;
        value.traits.forEach((element) => {
          if (element.trait_type === 'eye' && element.value === eyeList[eye.current]) {
            isWant = true;
          }
        });
        return isWant;
      });
    }
    if (figure.current !== -1) {
      _totalDatas = _totalDatas.filter((value) => {
        let isWant: boolean = false;
        value.traits.forEach((element) => {
          if (element.trait_type === 'figure' && element.value === figureList[figure.current]) {
            isWant = true;
          }
        });
        return isWant;
      });
    }
    if (hair.current !== -1) {
      _totalDatas = _totalDatas.filter((value) => {
        let isWant: boolean = false;
        value.traits.forEach((element) => {
          if (element.trait_type === 'hair' && element.value === hairList[hair.current]) {
            isWant = true;
          }
        });
        return isWant;
      });
    }
    if (hornLeft.current !== -1) {
      _totalDatas = _totalDatas.filter((value) => {
        let isWant: boolean = false;
        value.traits.forEach((element) => {
          if (element.trait_type === 'horn_left' && element.value === hornLeftList[hornLeft.current]) {
            isWant = true;
          }
        });
        return isWant;
      });
    }
    if (hornRight.current !== -1) {
      _totalDatas = _totalDatas.filter((value) => {
        let isWant: boolean = false;
        value.traits.forEach((element) => {
          if (element.trait_type === 'horn_right' && element.value === hornRightList[hornRight.current]) {
            isWant = true;
          }
        });
        return isWant;
      });
    }
    if (mouth.current !== -1) {
      _totalDatas = _totalDatas.filter((value) => {
        let isWant: boolean = false;
        value.traits.forEach((element) => {
          if (element.trait_type === 'Mouth' && element.value === mouthList[mouth.current]) {
            isWant = true;
          }
        });
        return isWant;
      });
    }
    if (tattoo.current !== -1) {
      _totalDatas = _totalDatas.filter((value) => {
        let isWant: boolean = false;
        value.traits.forEach((element) => {
          if (element.trait_type === 'tattoo' && element.value === tattooList[tattoo.current]) {
            isWant = true;
          }
        });
        return isWant;
      });
    }
    if (weapon.current !== -1) {
      _totalDatas = _totalDatas.filter((value) => {
        let isWant: boolean = false;
        value.traits.forEach((element) => {
          if (element.trait_type === 'weapon' && element.value === weaponList[weapon.current]) {
            isWant = true;
          }
        });
        return isWant;
      });
    }

    setCurrentDatas(_totalDatas);

    let rangeStart: number = 0;
    let rangeEnd: number = 0;

    rangeStart = 0;
    if (20 > _totalDatas.length) {
      rangeEnd = _totalDatas.length;
    } else {
      rangeEnd = 20;
    }

    const _currentPageData: NFT[] = [..._totalDatas.slice(rangeStart, rangeEnd)];
    setCurrentPageData(_currentPageData);
    setCurrentPage(0);
    if (rangeEnd === currentDatas.length) {
      setHasNextPage(false);
    }
  };

  const getNextPage = async () => {
    if (!hasNextPage) {
      return;
    }

    let rangeStart: number = 0;
    let rangeEnd: number = 0;

    rangeStart = (currentPage + 1) * 20;
    if ((currentPage + 2) * 20 > currentDatas.length) {
      rangeEnd = currentDatas.length;
    } else {
      rangeEnd = (currentPage + 2) * 20;
    }
    const _currentPageData: NFT[] = [...currentPageData, ...currentDatas.slice(rangeStart, rangeEnd)];
    setCurrentPageData(_currentPageData);
    setCurrentPage(currentPage + 1);
    if (rangeEnd === currentDatas.length) {
      setHasNextPage(false);
    }
  };

  useEffect(() => {
    const _totalDatas: NFT[] = [];
    // @ts-ignore
    for (var i = 0; i < datas.length; i++) {
      if (i === 20) {
        const _currentPageData: NFT[] = [..._totalDatas];
        setCurrentPageData(_currentPageData);
        setCurrentPage(0);
      }
      _totalDatas.push((datas as NFT[])[i]);
    }
    setTotalDatas(_totalDatas);
    setCurrentDatas(_totalDatas);

    return () => {};
  }, []);

  return (
    <div className="gallery">
      <div className="gallery-filter">
        <div className="gallery-filter-title">Filter</div>
        <Accordion
          sx={{
            border: 'none',
            boxShadow: 'none',
            borderRadius: '.16rem',
            marginBottom: '.12rem',
            '&:before': {
              display: 'none',
            },
          }}
        >
          <AccordionSummary
            expandIcon={<img className="gallery-filter-arrow" src={Arrow} alt="arrow" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              fontSize: '.24rem',
              lineHeight: '.33rem',
              backgroundColor: '#F9F9F9',
              border: 'none',
            }}
          >
            Background
          </AccordionSummary>
          <AccordionDetails>
            {backgroundList.map((value, index) => (
              <div className="gallery-filter-selector" key={`background-${value}`}>
                <Checkbox
                  sx={{
                    fontSize: '.2rem',
                    '& .MuiSvgIcon-root': { fontSize: '.2rem' },
                  }}
                  checked={background.current === index}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onBackgroundChange(index, event);
                  }}
                />

                <span className="gallery-filter-selector-name">{value}</span>
              </div>
            ))}
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            border: 'none',
            boxShadow: 'none',
            borderRadius: '.16rem',
            marginBottom: '.12rem',
            '&:before': {
              display: 'none',
            },
          }}
        >
          <AccordionSummary
            expandIcon={<img className="gallery-filter-arrow" src={Arrow} alt="arrow" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              fontSize: '.24rem',
              lineHeight: '.33rem',
              backgroundColor: '#F9F9F9',
              border: 'none',
            }}
          >
            Clothing
          </AccordionSummary>
          <AccordionDetails>
            {clothingList.map((value, index) => (
              <div className="gallery-filter-selector" key={`clothing-${value}`}>
                <Checkbox
                  sx={{
                    fontSize: '.2rem',
                    '& .MuiSvgIcon-root': { fontSize: '.2rem' },
                  }}
                  checked={clothing.current === index}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.checked) {
                      clothing.current = index;
                    } else {
                      clothing.current = -1;
                    }
                    initList();
                  }}
                />

                <span className="gallery-filter-selector-name">{value}</span>
              </div>
            ))}
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            border: 'none',
            boxShadow: 'none',
            borderRadius: '.16rem',
            marginBottom: '.12rem',
            '&:before': {
              display: 'none',
            },
          }}
        >
          <AccordionSummary
            expandIcon={<img className="gallery-filter-arrow" src={Arrow} alt="arrow" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              fontSize: '.24rem',
              lineHeight: '.33rem',
              backgroundColor: '#F9F9F9',
              border: 'none',
            }}
          >
            Earrings
          </AccordionSummary>
          <AccordionDetails>
            {earringsList.map((value, index) => (
              <div className="gallery-filter-selector" key={`earrings-${value}`}>
                <Checkbox
                  sx={{
                    fontSize: '.2rem',
                    '& .MuiSvgIcon-root': { fontSize: '.2rem' },
                  }}
                  checked={earrings.current === index}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.checked) {
                      earrings.current = index;
                    } else {
                      earrings.current = -1;
                    }
                    initList();
                  }}
                />

                <span className="gallery-filter-selector-name">{value}</span>
              </div>
            ))}
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            border: 'none',
            boxShadow: 'none',
            borderRadius: '.16rem',
            marginBottom: '.12rem',
            '&:before': {
              display: 'none',
            },
          }}
        >
          <AccordionSummary
            expandIcon={<img className="gallery-filter-arrow" src={Arrow} alt="arrow" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              fontSize: '.24rem',
              lineHeight: '.33rem',
              backgroundColor: '#F9F9F9',
              border: 'none',
            }}
          >
            Eye
          </AccordionSummary>
          <AccordionDetails>
            {eyeList.map((value, index) => (
              <div className="gallery-filter-selector" key={`eye-${value}`}>
                <Checkbox
                  sx={{
                    fontSize: '.2rem',
                    '& .MuiSvgIcon-root': { fontSize: '.2rem' },
                  }}
                  checked={eye.current === index}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.checked) {
                      eye.current = index;
                    } else {
                      eye.current = -1;
                    }
                    initList();
                  }}
                />

                <span className="gallery-filter-selector-name">{value}</span>
              </div>
            ))}
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            border: 'none',
            boxShadow: 'none',
            borderRadius: '.16rem',
            marginBottom: '.12rem',
            '&:before': {
              display: 'none',
            },
          }}
        >
          <AccordionSummary
            expandIcon={<img className="gallery-filter-arrow" src={Arrow} alt="arrow" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              fontSize: '.24rem',
              lineHeight: '.33rem',
              backgroundColor: '#F9F9F9',
              border: 'none',
            }}
          >
            Figure
          </AccordionSummary>
          <AccordionDetails>
            {figureList.map((value, index) => (
              <div className="gallery-filter-selector" key={`figure-${value}`}>
                <Checkbox
                  sx={{
                    fontSize: '.2rem',
                    '& .MuiSvgIcon-root': { fontSize: '.2rem' },
                  }}
                  checked={figure.current === index}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.checked) {
                      figure.current = index;
                    } else {
                      figure.current = -1;
                    }
                    initList();
                  }}
                />

                <span className="gallery-filter-selector-name">{value}</span>
              </div>
            ))}
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            border: 'none',
            boxShadow: 'none',
            borderRadius: '.16rem',
            marginBottom: '.12rem',
            '&:before': {
              display: 'none',
            },
          }}
        >
          <AccordionSummary
            expandIcon={<img className="gallery-filter-arrow" src={Arrow} alt="arrow" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              fontSize: '.24rem',
              lineHeight: '.33rem',
              backgroundColor: '#F9F9F9',
              border: 'none',
            }}
          >
            Hair
          </AccordionSummary>
          <AccordionDetails>
            {hairList.map((value, index) => (
              <div className="gallery-filter-selector" key={`hair-${value}`}>
                <Checkbox
                  sx={{
                    fontSize: '.2rem',
                    '& .MuiSvgIcon-root': { fontSize: '.2rem' },
                  }}
                  checked={hair.current === index}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.checked) {
                      hair.current = index;
                    } else {
                      hair.current = -1;
                    }
                    initList();
                  }}
                />

                <span className="gallery-filter-selector-name">{value}</span>
              </div>
            ))}
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            border: 'none',
            boxShadow: 'none',
            borderRadius: '.16rem',
            marginBottom: '.12rem',
            '&:before': {
              display: 'none',
            },
          }}
        >
          <AccordionSummary
            expandIcon={<img className="gallery-filter-arrow" src={Arrow} alt="arrow" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              fontSize: '.24rem',
              lineHeight: '.33rem',
              backgroundColor: '#F9F9F9',
              border: 'none',
            }}
          >
            Horn Left
          </AccordionSummary>
          <AccordionDetails>
            {hornLeftList.map((value, index) => (
              <div className="gallery-filter-selector" key={`horn-left-${value}`}>
                <Checkbox
                  sx={{
                    fontSize: '.2rem',
                    '& .MuiSvgIcon-root': { fontSize: '.2rem' },
                  }}
                  checked={hornLeft.current === index}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.checked) {
                      hornLeft.current = index;
                    } else {
                      hornLeft.current = -1;
                    }
                    initList();
                  }}
                />

                <span className="gallery-filter-selector-name">{value}</span>
              </div>
            ))}
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            border: 'none',
            boxShadow: 'none',
            borderRadius: '.16rem',
            marginBottom: '.12rem',
            '&:before': {
              display: 'none',
            },
          }}
        >
          <AccordionSummary
            expandIcon={<img className="gallery-filter-arrow" src={Arrow} alt="arrow" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              fontSize: '.24rem',
              lineHeight: '.33rem',
              backgroundColor: '#F9F9F9',
              border: 'none',
            }}
          >
            Horn Right
          </AccordionSummary>
          <AccordionDetails>
            {hornRightList.map((value, index) => (
              <div className="gallery-filter-selector" key={`horn-right-${value}`}>
                <Checkbox
                  sx={{
                    fontSize: '.2rem',
                    '& .MuiSvgIcon-root': { fontSize: '.2rem' },
                  }}
                  checked={hornRight.current === index}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.checked) {
                      hornRight.current = index;
                    } else {
                      hornRight.current = -1;
                    }
                    initList();
                  }}
                />

                <span className="gallery-filter-selector-name">{value}</span>
              </div>
            ))}
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            border: 'none',
            boxShadow: 'none',
            borderRadius: '.16rem',
            marginBottom: '.12rem',
            '&:before': {
              display: 'none',
            },
          }}
        >
          <AccordionSummary
            expandIcon={<img className="gallery-filter-arrow" src={Arrow} alt="arrow" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              fontSize: '.24rem',
              lineHeight: '.33rem',
              backgroundColor: '#F9F9F9',
              border: 'none',
            }}
          >
            Nouth
          </AccordionSummary>
          <AccordionDetails>
            {mouthList.map((value, index) => (
              <div className="gallery-filter-selector" key={`mouth-${value}`}>
                <Checkbox
                  sx={{
                    fontSize: '.2rem',
                    '& .MuiSvgIcon-root': { fontSize: '.2rem' },
                  }}
                  checked={mouth.current === index}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.checked) {
                      mouth.current = index;
                    } else {
                      mouth.current = -1;
                    }
                    initList();
                  }}
                />

                <span className="gallery-filter-selector-name">{value}</span>
              </div>
            ))}
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            border: 'none',
            boxShadow: 'none',
            borderRadius: '.16rem',
            marginBottom: '.12rem',
            '&:before': {
              display: 'none',
            },
          }}
        >
          <AccordionSummary
            expandIcon={<img className="gallery-filter-arrow" src={Arrow} alt="arrow" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              fontSize: '.24rem',
              lineHeight: '.33rem',
              backgroundColor: '#F9F9F9',
              border: 'none',
            }}
          >
            Tattoo
          </AccordionSummary>
          <AccordionDetails>
            {tattooList.map((value, index) => (
              <div className="gallery-filter-selector" key={`tattoo-${value}`}>
                <Checkbox
                  sx={{
                    fontSize: '.2rem',
                    '& .MuiSvgIcon-root': { fontSize: '.2rem' },
                  }}
                  checked={tattoo.current === index}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.checked) {
                      tattoo.current = index;
                    } else {
                      tattoo.current = -1;
                    }
                    initList();
                  }}
                />

                <span className="gallery-filter-selector-name">{value}</span>
              </div>
            ))}
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            border: 'none',
            boxShadow: 'none',
            borderRadius: '.16rem',
            marginBottom: '.12rem',
            '&:before': {
              display: 'none',
            },
          }}
        >
          <AccordionSummary
            expandIcon={<img className="gallery-filter-arrow" src={Arrow} alt="arrow" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              fontSize: '.24rem',
              lineHeight: '.33rem',
              backgroundColor: '#F9F9F9',
              border: 'none',
            }}
          >
            Weapon
          </AccordionSummary>
          <AccordionDetails>
            {weaponList.map((value, index) => (
              <div className="gallery-filter-selector" key={`weapon-${value}`}>
                <Checkbox
                  sx={{
                    fontSize: '.2rem',
                    '& .MuiSvgIcon-root': { fontSize: '.2rem' },
                  }}
                  checked={weapon.current === index}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.checked) {
                      weapon.current = index;
                    } else {
                      weapon.current = -1;
                    }
                    initList();
                  }}
                />

                <span className="gallery-filter-selector-name">{value}</span>
              </div>
            ))}
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="gallery-contents">
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={getNextPage}
          hasMore={hasNextPage}
          threshold={100}
          loader={
            <div className="gallery-loader" key={0}>
              Loading ...
            </div>
          }
        >
          <div className="gallery-contents-nfts">
            {currentPageData.map((value) => (
              <div key={`nft-${value.name}`} className="gallery-contents-nfts-item">
                <img src={`https://bzuki.s3.ap-southeast-1.amazonaws.com/images/resize/${value.image}`} alt="nft" />
                <div className="gallery-contents-nfts-item-infos">
                  <div className="gallery-contents-nfts-item-infos-name">{value.name}</div>
                </div>
              </div>
            ))}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default GalleryPage;
